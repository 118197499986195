import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './Footer.less';
import { withRouter } from 'react-router-dom';
import { umeHistory } from '@/utils/route';
import SVGHome from './Svg/Home';
import SVGMine from './Svg/Mine';
import SVGDiscover from './Svg/Discover';
import { AffixBottom } from '@/design';
import SVGSocial from './Svg/Social';
import { Ga_Send } from '@/utils/business';

const Footer: React.FC<any> = props => {

  const [current, setCurrent] = useState('/')

  const handleLinkNav = (key: string) => {
    const preBraId = localStorage.getItem('braId');
    if (key === 'store') key = `${key}/${preBraId}`
    umeHistory.switchTo(`/${key}`)
  }

  useEffect(() => {
    const { pathname = '/' } = props.history.location
    let realPath = pathname.match(/^(\/[a-zA-Z]*)\/?/)[1]
    setCurrent(realPath)
  }, [props])

  return createPortal(
    <>
      {/* <div className={styles.footer_hidden}></div> */}
      <AffixBottom className={styles.footer_main}>
        <a className={`${styles.item} ${current === '/store' ? 'active' : ''}`} onClick={() => { Ga_Send('我的', '點擊在店'); handleLinkNav('store') }} >
          {/* <SVGHome /> */}
          <i className={styles.icon_store}></i>
          <span>在店</span>
        </a>
        {/* <SVGDiscover /> */}
        {/* <a className={`${styles.item} ${current === '/' ? 'active' : ''}`} onClick={() => { Ga_Send('我的', '點擊更多好店'); handleLinkNav('') }} >
          <i className={styles.icon_discover}></i>
          <span>更多好店</span>
        </a> */}
        {/* <a className={`${styles.item} ${current === '' ? 'active' : ''}`} onClick={() => { handleLinkNav('social') }} >
          <SVGSocial />
          <span>百草園</span>
        </a> */}
        <a className={`${styles.item} ${current === '/mine' ? 'active' : ''}`} onClick={() => { Ga_Send('我的', '點擊我的'); handleLinkNav('mine') }}>
          {/* <SVGMine /> */}
          <i className={styles.icon_mine}></i>
          <span>我的</span>
        </a>
      </AffixBottom>
    </>,
    document.body,
  );
};

export default withRouter(Footer);
