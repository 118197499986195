import { Message } from '@/design';
import { isEmpty } from '@/utils/common';
import { umeHistory } from '@/utils/route';
import React, { useEffect } from 'react';

// 登錄權限校驗
const AuthWrapper: React.FC<any> = props => {

  const chechLoginAuth = () => {
    let userInfo = localStorage.getItem('userInfo');
    if (isEmpty(userInfo)) {
      Message.warn({ content: '請先登入ume會員' });
      umeHistory.replace('/signin')
      return null;
    }
  }

  useEffect(() => {
    chechLoginAuth()
  }, [])

  return isEmpty(localStorage.getItem('userInfo')) ? null : props.children;
};

export default AuthWrapper;