import { request } from '@/utils/request';
import { stringify } from 'qs';

/** 查詢用戶信息 */
export function queryUserInfo(params: IApiParam.UserInfo) {
  return request(`/v1/ume/client/my/info?${stringify(params)}`)
}

/** 修改用戶信息 */
export function queryUserInfoEdit(data: IUserInfo) {
  return request(`/v1/ume/client/my/info`, { method: 'PUT', data })
}

/** 查詢用戶優惠券 */
export function queryUserCoupon(params: IApiParam.UserCoupon) {
  return request(`/v1/ume/client/my/coupon?${stringify(params)}`)
}

/** 我收藏的店鋪 */
export function queryUserCollectStore(params: IApiParam.UserCollectStore) {
  return request(`/v1/ume/client/my/collect/store/list?${stringify(params)}`)
}

/** 收藏店鋪 */
export function queryCollectStore(data: IApiParam.CollectStore) {
  return request(`/v1/ume/client/my/collect/store?${stringify(data)}`, { method: 'POST' })
}

/** 取消收藏店鋪 */
export function queryCollectStoreCancel(data: IApiParam.CollectStore) {
  return request(`/v1/ume/client/my/collect/store/cancel?${stringify(data)}`, { method: 'POST' })
}

/** 查询零售订单 */
export function queryPosOrderList(data: IApiParam.PosOrderList) {
  return request(`/v1/ume/client/order/shop/list?${stringify(data)}`)
}

/** 查询餐飲订单 */
export function queryFnbOrderList(data: IApiParam.FnbOrderList) {
  return request(`/v1/ume/client/fnb/order/list?${stringify(data)}`)
}

/** 提交投訴建議 */
export function queryPushSuggest(data: IApiParam.PushSuggest) {
  return request(`/v1/ume/client/my/suggestion`, { method: 'POST', data })
}

/** 消息中心未讀數 */
export function queryMsgUnreadNum(data: IApiParam.UnreadNum) {
  return request(`/v1/ume/client/my/message/unreadNum?${stringify(data)}`)
}

/** 系統消息未讀數 */
export function querySystemMsgUnreadNum(data: IApiParam.UnreadNum) {
  return request(`/v1/ume/client/my/message/system/unreadNum?${stringify(data)}`)
}

/** 會員消息未讀數 */
export function queryDiscountMsgUnreadNum(data: IApiParam.UnreadNum) {
  return request(`/v1/ume/client/my/message/discount/unreadNum?${stringify(data)}`)
}

/** 我的門店消息 */
export function queryMyMessageStore(data: IApiParam.UnreadNum) {
  return request(`/v1/ume/client/my/message/store?${stringify(data)}`)
}

/** 我的系統消息 */
export function queryMyMessageSystem(data: IApiParam.MessageList) {
  return request(`/v1/ume/client/my/message/system/list?${stringify(data)}`)
}

/** 讀消息 */
export function queryMyMessageRead(data: IApiParam.MyMessageRead) {
  return request(`/v1/ume/client/my/message/read?${stringify(data)}`, { method: 'PUT' })
}

/** 待評價訂單 */
export function queryUncommentOrder(data: IApiParam.UncommentOrder) {
  return request(`/v1/ume/client/order/shop/uncomment?${stringify(data)}`)
}

/** 刪除待評價訂單 */
export function queryUncommentDelete(orderId: string, params: any) {
  return request(`/v1/ume/client/order/shop/uncomment/delete/${orderId}?${stringify(params)}`, { method: 'DELETE' })
}

/** 投訴待評價訂單 */
export function queryUncommentComplaint(data: IApiParam.UncommentComplaint) {
  return request(`/v1/ume/client/my/complain`, { method: 'POST', data })
}

/** 投訴待評價訂單-原因 */
export function queryUncommentComplaintReason() {
  return request(`/v1/ume/client/my/complain/categoryList`)
}

/** 查詢收貨地址列表 */
export function queryAddressList(params: { userId: string }) {
  return request(`/v1/ume/client/my/shipping/address/all?${stringify(params)}`)
}

/** 新增收貨地址 */
export function queryAddressAdd(data: IApiParam.AddressAdd) {
  return request(`/v1/ume/client/my/shipping/address`, { method: 'POST', data })
}

/** 刪除收貨地址 */
export function queryAddressDelete(addressId: string) {
  return request(`/v1/ume/client/my/shipping/address/${addressId}`, { method: 'DELETE' })
}

/** 查看收貨地址 */
export function queryAddressDetail(addressId: string) {
  return request(`/v1/ume/client/my/shipping/address/${addressId}`)
}

/** 修改收貨地址 */
export function queryAddressEdit(addressId: string, data: IApiParam.AddressEdit) {
  return request(`/v1/ume/client/my/shipping/address/${addressId}`, { method: 'PUT', data })
}

/** 發票列表 */
export function queryInvoiceList(params: IApiParam.InvoiceList) {
  return request(`/v1/ume/client/invoice/list?${stringify(params)}`)
}

/** 發票詳情 */
export function queryInvoiceDetail(invoiceNumber: string) {
  return request(`/v1/ume/client/invoice/detail/${invoiceNumber}`)
}

/** 門店消息-列表 */
export function queryStoreNoticeList() {
  return request(`/v1/ume/client/message/storeMsg/store`)
}

/** 門店消息-詳情 */
export function queryStoreNoticeDetail(params: IApiParam.StoreNoticeDetail) {
  return request(`/v1/ume/client/message/storeMsg/detail?${stringify(params)}`)
}

/** 門店消息-標為已讀 */
export function queryStoreNoticeRead(braId: string) {
  return request(`/v1/ume/client/message/storeMsg/read/${braId}`, { method: 'PUT' })
}

/** 門店消息-刪除 */
export function queryStoreNoticeDel(braId: string) {
  return request(`/v1/ume/client/message/storeMsg/del/${braId}`, { method: 'PUT' })
}

/** 我的點讚-列表 */
export function queryLikeNoticeList(params: IApiParam.PageParams) {
  return request(`/v1/ume/client/comment/like/list?${stringify(params)}`)
}

/** 我的點讚-標為已讀 */
export function queryLikeNoticeRead() {
  return request(`/v1/ume/client/comment/like/read`, { method: 'PUT' })
}

/** 我的評論-列表 */
export function queryReplyNoticeList(params: IApiParam.PageParams) {
  return request(`/v1/ume/client/comment/commentReplyList?${stringify(params)}`)
}

/** 我的評論-標為已讀 */
export function queryReplyNoticeRead() {
  return request(`/v1/ume/client/comment/commentReply/read`, { method: 'PUT' })
}

/** 專屬優惠-列表 */
export function queryDiscountNoticeList(params: IApiParam.PageParams) {
  return request(`/v1/ume/client/my/message/discount/list?${stringify(params)}`)
}

/** 我的消息-全部已讀 */
export function queryNoticeClear() {
  return request(`/v1/ume/client/my/message/clear`, { method: 'PUT' })
}

/** 系統消息-全部已讀 */
export function querySystemNoticeClear() {
  return request(`/v1/ume/client/my/message/systemNotice/clearRead`, { method: 'PUT' })
}