import React, { useState, useEffect, useRef } from 'react';
import styles from './Picture.less';
import Pic_Hold from '@/assets/images/pic_hold.png';
import { getMockPic, isEmpty } from '@/utils/common';
import Spinning from '../Spinning/Spinning';
import ReactDOM, { createPortal } from 'react-dom';
import PicView from './PicView';
import Modal from '../Modal/Modal';

type IPicture = {
  src?: string
  mock?: [number, number]
  className?: string
  setRef?: HTMLDivElement | null
  unObserve?: boolean
  onClick?: any
  view?: boolean
}

const Picture: React.FC<IPicture> = props => {
  const [loading, setLoading] = useState(false); // loading状态
  const [pic, setPic] = useState(''); // 图片路径暂存
  const picRef = useRef<HTMLDivElement>(null);
  const ioRef = useRef<IntersectionObserver>();

  const initObserve = () => {
    /* 創建視口觀察 */
    ioRef.current = new IntersectionObserver(function (entries) {
      // console.log(entries[0].intersectionRatio);
      // 如果不可見則不加載圖片
      if (entries[0].intersectionRatio <= 0) return;
      // 加載圖片的同時釋放觀察
      loadPic();
      ioRef.current &&
        ioRef.current.unobserve(
          !isEmpty(props.setRef)
            ? (props.setRef as Element)
            : (picRef.current as Element),
        );
    });
    ioRef.current.observe(
      !isEmpty(props.setRef)
        ? (props.setRef as Element)
        : (picRef.current as Element),
    );
  };

  const loadPic = () => {
    let picPath = props.mock ? getMockPic(...props.mock) : props.src;
    // 判断是否有图
    if (
      !picPath ||
      (picPath.indexOf('http') < 0 && picPath.indexOf('base64') < 0)
    ) {
      return false;
    }
    if (window.location.origin.indexOf('portal2.umeshop.ai') > -1) {
      // 大陸訪問切換圖片路徑
      picPath = picPath.replace('umeclient.umeshop.ai', 'umeclient1.umeshop.ai')
    }
    // console.log(window.location.origin)
    // 新建img标签
    let newPic = document.createElement('img');
    // console.log(picPath)
    // 设置img标签样式
    newPic.style.overflow = 'hidden';
    newPic.style.opacity = '0';
    newPic.style.width = '0px';
    newPic.style.height = '0px';
    newPic.src = picPath;
    // 添加onload方法
    newPic.onload = () => {
      // 加载完成后删除img标签，并显示实际的dom
      // console.log(newPic.naturalWidth)
      document.body.removeChild(newPic);
      setLoading(false);
      setPic(picPath || '');
    };
    newPic.onerror = () => {
      // 加载失败后删除img标签，并显示占位图
      document.body.removeChild(newPic);
      setLoading(false);
      setPic('');
      console.warn(`圖片加載失敗，鏈接：${picPath}`);
    };
    // 将img标签添加至body下，进行隐藏式加载
    document.body.appendChild(newPic);
    setLoading(true);
  };

  /** 預覽圖片 */
  const handleViewImg = (event: React.MouseEvent) => {
    if (!props?.view) return false;
    event.stopPropagation();
    Modal.wrapper({
      maskClose: true,
      content: <img className={styles.view_img} src={props.src} alt={props.src} />
    })
    return false;
  }

  useEffect(() => {
    if (props.unObserve) {
      loadPic();
    } else {
      initObserve();
    }
    return () => {
      ioRef.current &&
        ioRef.current.unobserve(
          !isEmpty(props.setRef)
            ? (props.setRef as Element)
            : (picRef.current as Element),
        );
    };
  }, [props.src]);

  return (
    <>
      <div ref={picRef} className={`${styles.pic_item} ${props.className}`} onClick={() => { props?.onClick && props.onClick() }}>
        {loading ? (
          <Spinning state="dark" type="circle" size={32} />
        ) : !isEmpty(pic) ? (
          <img className={styles.real} src={pic} alt={props.src} onClick={handleViewImg} />
        ) : (
              <img className={styles.wait} src={Pic_Hold} alt={props.src} />
            )}
      </div>
      {/* {createPortal(<PicView />, document.body)} */}
    </>
  );
};

export default Picture;
