import { is_test, is_pre_push, is_dev } from '../../package.json';

/** 是否處於開發模式 */
export const IS_DEV = is_dev

/** 是否訪問測試環境資源 否則為正式環境 */
export const IS_TEST = is_test

/** 是否為預發佈版本 */
export const IS_PRE_PUSH = is_pre_push

/** 圖片路徑 */
export const PIC_PATH = IS_TEST ? 'http://umeclient.trendlab.cc' : "https://umeclient.umeshop.ai"

/** 零售服務端地址 */
export const POS_SERVICE_PATH = IS_TEST ? 'http://umepos.trendlab.cc' : 'https://umepos.trendlab.ai'

/** 零售H5域名 */
export const POS_PATH = IS_DEV
  ? 'http://192.168.1.161:9001'
  : (IS_TEST
    ? 'https://umepass-cc.trendlab.ai'
    : (IS_PRE_PUSH
      ? 'https://pre-pass.umeshop.ai'
      : 'https://pass.umeshop.ai'
    )
  )

/** 餐飲H5域名 */
export const FNB_PATH = IS_DEV
  ? 'http://192.168.1.107:9000'
  // ? 'http://fnb-qrcode.trendlab.cc/v15'
  : (IS_TEST
    ? 'http://fnb-qrcode.trendlab.cc/v15'
    : (IS_PRE_PUSH
      ? 'https://pre-fnb-qrcode.trendlab.ai/v15'
      : 'https://fnb-qrcode.trendlab.ai/v15'
    )
  )

/** Line登入配置 */
export const LINE_LOGIN_CONFIG = {
  path: 'https://access.line.me/oauth2/v2.1/authorize',
  redirectUrl: `${window.location.origin}/third/line`,
  channelId: '1655278512',
  nonce: 'ume-portal',
}

/** APP下載鏈接 */
export const APP_DOWNLOAD = IS_TEST ? 'http://umedownload.trendlab.cc/' : 'https://umedownload.umeshop.ai/'

/** Google map */
export const GOOGLE_MAP_KEY = 'AIzaSyBgTi8gPENB6yFesZL29uzGwVl0CsI2J4k';