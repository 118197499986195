import React from 'react';
import styles from './Switch.less';

interface IDSwitch {
  checked: boolean
  onChange: (checked: boolean) => void
}

export default function (props: IDSwitch) {
  return <span
    className={`${styles.switch} ${props.checked ? styles.active : ''}`}
    onClick={() => { props.onChange && props.onChange(!props.checked) }
    }> <i></i></span >
}