import { isEmpty } from '@/utils/common';
import React, { useContext, createContext, useState, useEffect, useMemo } from 'react';
import styles from './Radio.less';

interface IContext {
  value?: string,
  name?: string
  onChange: (value: string) => void
}

let RadioContext: React.Context<IContext>;

interface IRadioGroup {
  defaultValue?: any
  value?: any
  name?: string
  onChange: (res: any) => void
}

/** 組 */
export const RadioGroup: React.FC<IRadioGroup> = props => {

  const [current, setCurrent] = useState<string>(props.defaultValue || '');

  /** 監聽修改 */
  const handleRadioChange = (value: string) => {
    // console.log(e.target.value)
    setCurrent(value)
    props.onChange && props.onChange(value)
  }

  /** 創建context */
  RadioContext = createContext<IContext>({
    value: current,
    name: props.name,
    onChange: handleRadioChange
  })

  /** 受控組件 */
  useEffect(() => {
    if (!isEmpty(props.value)) {
      setCurrent(props.value)
    }
  }, [props.value])

  return <RadioContext.Provider value={{
    value: current,
    name: props.name,
    onChange: handleRadioChange
  }}>
    {props.children}
  </RadioContext.Provider>
}

interface IRadio {
  defaultChecked?: boolean
  name?: any
  value: any
  onChange?: (res: any) => void
  leftText?: boolean
  className?: string
}

/** 按鈕 */
export const Radio: React.FC<IRadio> = props => {

  const context = useContext(RadioContext);

  /** 監聽修改，並判斷是否有context */
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target

    props.onChange && props.onChange(value)
    if (context) {
      context?.onChange(value)
    } else {
      props.onChange && props.onChange(value);
    }
  }

  /** 判斷是否選中 */
  const isChecked = useMemo(() => {
    return context ? props.value === context.value : props.defaultChecked
  }, [props, context])

  return <label className={`${styles.radio_wrap} ${props.className || ''}`}>
    {props.leftText && <span className={styles.lt}>{props.children}</span>}
    <input
      key={`${props.name}_${props.value}`}
      type="radio"
      className={styles.radio_item}
      name={context?.name || props.name}
      value={props.value}
      defaultChecked={props.defaultChecked}
      checked={isChecked}
      onChange={handleRadioChange}
    />
    {!props.leftText && <span className={styles.rt}>{props.children}</span>}
  </label>
}