import React, { useEffect, useMemo } from 'react';
import Footer from '@/components/Footer/Footer';
import { withRouter } from 'react-router-dom';
import { useStore } from 'dva'
import { persistStore } from 'redux-persist';
import { umeHistory } from '@/utils/route';

const Layout: React.FC<any> = props => {

  const store = useStore();

  const checkFootAuth = useMemo(() => {
    let authRoute = [`/store/${localStorage.getItem('braId')}`, '/', '/mine']
    let realPath = props.location.pathname
    // console.log("🚀 ~ file: layout.tsx ~ line 10 ~ checkFootAuth ~ realPath", realPath, realPath.match(/^\/store\/\d+/))
    return authRoute.indexOf(realPath) > -1 || realPath.match(/^\/store\/\d+/);
  }, [props])

  useEffect(() => {
    persistStore(store); // 数据持久化
  }, [store])

  useEffect(() => {
    window.addEventListener("popstate", function (e) {
      umeHistory.checkRoute();
    }, false);
    window.addEventListener("pageshow", function (e) {
      if (localStorage.getItem('isPageHide') === 'Y') {
        // console.log(e);
        localStorage.removeItem('isPageHide')
        window.location.reload();
      }
    });
  }, [])

  return (
    <>
      {props.children}
      {checkFootAuth && <Footer />}
    </>
  );
};

export default withRouter(Layout);
