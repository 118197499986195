import { Message, Modal } from "@/design";
import { queryIsMerMember, queryStoreInfo } from "@/services/store";
import { linkPosPath } from "@/utils/business";
import { getPayPlatform, isEmpty, JsonStorge } from "@/utils/common";

export default {
  namespace: "store",
  state: {
    currentStore: {},
    memberInfo: {},
  },
  effects: {
    *fetchStoreInfo({ payload, callback }: any, { put, call }: IDvaOperation) {
      let result = yield call(queryStoreInfo, payload);
      if (!result || !result.data) return false;
      yield put({
        type: "saveStoreInfo",
        payload: result.data,
      });
      if (result.data) {
        if (result.data.industryType === "pos") {
          // 零售邏輯
          let plat = getPayPlatform();
          let isFirst = sessionStorage.getItem("isLine") !== "Y";
          if (plat === "linepay" && isFirst) {
            // 判斷是否是line掃碼進入零售
            sessionStorage.setItem("isLine", "Y");
            linkPosPath("cashier", { braId: localStorage.getItem("braId") });
            return false;
          }
        } else {
          // 餐飲邏輯
          if (isEmpty(payload?.codeId)) {
            let isFirst = sessionStorage.getItem("isShare") !== "Y";
            if (isFirst) {
              sessionStorage.setItem("isShare", "Y");
              Modal.alert({
                content: "您當前進入的是外帶模式，如需內用，請掃店內桌台碼！",
              });
            }
          } else if (isEmpty(result.data?.tableCode)) {
            Modal.alert({
              content: "未查詢到對應的桌台號，請重掃店內桌台碼！",
            });
          }
        }
      }

      yield put({ type: "fetchMemberInfo", storeInfo: result.data, callback });
    },
    *fetchMemberInfo(
      { storeInfo, callback }: any,
      { put, call }: IDvaOperation
    ) {
      if (storeInfo.industryType !== "fnb") {
        localStorage.removeItem("codeId");
      }
      let userInfo = JsonStorge.get("userInfo");
      if (isEmpty(userInfo)) {
        yield put({ type: "saveMemberInfo", payload: {} });
        callback && callback(storeInfo);
        return false;
      }
      let result = yield call(queryIsMerMember, {
        merId: storeInfo.merId,
        userId: userInfo.userId,
      });
      // if (!result || isEmpty(result.data)) {
      //   callback && callback(storeInfo)
      //   return false
      // };
      yield put({
        type: "saveMemberInfo",
        payload: result?.data ? result.data?.memberGrade : {},
      });
      // console.log("🚀 ~ file: store.ts ~ line 32 ~ *fetchMemberInfo ~ callback", callback)
      callback &&
        callback(storeInfo, result?.data ? result.data?.memberGrade : {});
    },
  },
  reducers: {
    saveStoreInfo(state: any, { payload }: any) {
      return {
        ...state,
        currentStore: payload,
      };
    },
    saveMemberInfo(state: any, { payload }: any) {
      return {
        ...state,
        memberInfo: payload,
      };
    },
    clearStoreInfo(state: any) {
      return {
        ...state,
        currentStore: {},
        memberInfo: {},
      };
    },
  },
};
