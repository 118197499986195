import React, { useEffect, useRef, useState } from "react";

export default function (props: any) {

  const beginX = useRef(0);
  const [distance, setDistance] = useState(0);

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    beginX.current = event.changedTouches[0].pageX
  }

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    let diff = event.changedTouches[0].pageX - beginX.current;
    diff = diff > 0 ? 0 : diff
    diff = diff > -props.width ? diff : -props.width
    setDistance(diff);
  }

  const handleTouchEnd = () => {
    if (distance > (-props.width / 2)) {
      setDistance(0);
    } else {
      setDistance(-props.width);
    }
  }

  const clickListener = () => {
    setDistance(0);
  }

  useEffect(() => {
    window.addEventListener('click', clickListener, true)
    return () => {
      window.removeEventListener('click', clickListener, true)
    }
  }, [])

  return <div
    className={`touch ${props.className}`}
    style={{ transform: `translateX(${distance}px)` }}
    onTouchStart={handleTouchStart}
    onTouchMove={handleTouchMove}
    onTouchEnd={handleTouchEnd}
  >
    {props.children}
  </div>
}