import md5 from 'md5';

/**
 * 获取随机占位图
 *
 * @param {number} w 宽
 * @param {number} h 高
 * @return {*} https://unsplash.it/${w}/${h}?v=${Math.random()}
 */
export function getMockPic(w: number, h: number) {
  return `https://picsum.photos/${w}/${h}?v=${Math.random()}`;
}

/**
 * 为空判断
 *
 * @param {*} value
 * @return {*}  {boolean}
 */
export function isEmpty(value: any): boolean {
  switch (typeof value) {
    case 'undefined':
      return true;
    case 'string':
      return (
        value.trim() === '' ||
        value === '' ||
        value === 'undefined' ||
        value === 'null'
      );
    case 'object': {
      if (value instanceof Array) return value.length < 1;
      else if (value instanceof Date) return false;
      else if (value instanceof Object) return Object.keys(value).length < 1;
      else return true;
    }
    default:
      return false;
  }
}

/**
 * 打电话
 *
 * @param {string} num 电话号码
 * @return {*}
 */
export function callPhone(num: string): boolean {
  let merge = /^\d{8,16}$/;
  if (merge.test(num)) {
    let oA = document.createElement('a');
    oA.href = `tel:${num}`;
    oA.click();
    return true;
  } else {
    return false;
  }
}

/**
 * 等待
 *
 * @param {number} sec 秒s
 * @return {*} 返回 promise
 */
export function waitBy(sec: number): Promise<any> {
  let timed: any = undefined;
  return new Promise(resolve => {
    clearTimeout(timed);
    timed = setTimeout(() => {
      resolve(true);
      clearTimeout(timed);
    }, sec * 1000);
  });
}

/**
 * 防抖
 * - 阻断前面的执行，仅执行最后一次
 * @param {Function} fn 需执行的函数
 * @param {number} wait 等待的时间
 * @return {*}  {Function}
 */
export function debounce(fn: Function, wait: number): Function {
  let timer: NodeJS.Timeout | null = null;
  return function (this: any) {
    let context = this;
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, wait);
  };
}

/**
 * 防抖
 * - 阻断后面的执行，仅执行第一次
 * @param {Function} fn 需执行的函数
 * @param {number} wait 等待的时间
 * @return {*}  {Function}
 */
export function debounceAfter(fn: Function, wait: number): Function {
  let timer: NodeJS.Timeout | null = null;
  return function (this: any) {
    let context = this;
    let args = arguments;

    if (timer) clearTimeout(timer);

    let callNow = !timer;
    timer = setTimeout(() => {
      timer = null;
    }, wait);

    if (callNow) fn.apply(context, args);
  };
}

/**
 * @desc 函数节流
 * @param func 函数
 * @param wait 延迟执行毫秒数
 * @param type 1 时间戳版，2 定时器版
 */
export function throttle(func: Function, wait: number, type: number): Function {
  let previous = 0;
  let timeout: NodeJS.Timeout | null = null;
  return function (this: any) {
    let context = this;
    let args = arguments;
    if (type === 1) {
      let now = Date.now();

      if (now - previous > wait) {
        func.apply(context, args);
        previous = now;
      }
    } else if (type === 2) {
      if (!timeout) {
        timeout = setTimeout(() => {
          timeout = null;
          func.apply(context, args);
        }, wait);
      }
    }
  };
}

/**
 * 震动手机
 *
 * @param {(number | number[])} pattern 震动、暂停间隔的模式
 */
export function shakeIt(pattern: number | number[]) {
  if (navigator && navigator.vibrate) {
    navigator.vibrate(pattern);
  }
}

/** md5加密 */
export function encryptMd5(password: string | number): string {
  return md5(password).toLowerCase();
}

/** 操作localstorage數據 */
export const JsonStorge = {
  set: (name: string, data: any) => { localStorage.setItem(name, JSON.stringify(data)) },
  get: (name: string) => JSON.parse(localStorage.getItem(name) || '{}'),
  copy: (data: any) => JSON.parse(JSON.stringify(data)),
  remove: (name: string) => localStorage.removeItem(name)
}

/** 獲取平台類型 */
export function getPayPlatform() {
  let plat = 'default'
  let ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('line') > -1) {
    plat = 'linepay'
  } else if (ua.indexOf('gama') > -1) {
    plat = 'gamapay'
  } else if (ua.indexOf('ali') > -1) {
    plat = 'alipay'
  } else if (ua.indexOf('micromessenger') > -1) {
    plat = 'wechat'
  }
  return plat;
}