import React, { ReactNode } from 'react';
import SwiperCore, { Pagination, Autoplay, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.less';
import 'swiper/components/pagination/pagination.less';
import './SwiperBox.less';

SwiperCore.use([Pagination, Autoplay]);

type ISwiperBox = {
  ikey: string
  data: Array<any>
  renderRow: (item: any, index: number) => ReactNode
  options?: SwiperOptions
  wrapClass?: string
  itemClass?: string
  dotsClass?: string
  hideDots?: boolean
  onChange?: (res: any) => void
};

const SwiperBox: React.FC<ISwiperBox> = props => {

  return (
    <Swiper
      className={props.wrapClass}
      watchOverflow
      onSlideChange={(swiper) => { props.onChange && props.onChange(swiper) }}
      {...props.options}
    >
      {props.data.map((item, index) => (
        <SwiperSlide className={`${props.itemClass}`} key={item[props.ikey] || `${item}_${index}`}>{props.renderRow(item, index)}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperBox;
