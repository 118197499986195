import { request } from '@/utils/request';

/** 查詢用戶是否註冊 */
export function queryUserIsRegister(data: IApiParam.IsRegister) {
  return request(`/v1/ume/client/user/isregister`, {
    method: 'POST',
    data
  })
}

/** 发送短信验证码 */
export function queryUserSendCaptcha(data: IApiParam.SendCaptcha) {
  return request(`/v1/ume/client/user/sendsms`, {
    method: 'POST',
    data
  })
}

/** 手機號登入 */
export function queryUserLogin(data: IApiParam.UserSign) {
  return request(`/v1/ume/client/user/mobile/login`, {
    method: 'POST',
    data
  })
}

/** 手機號註冊 */
export function queryUserRegister(data: IApiParam.UserSign) {
  return request(`/v1/ume/client/user/register`, {
    method: 'POST',
    data
  })
}

/** Line登入 */
export function queryUserLineLogin(data: IApiParam.userLineVo) {
  return request(`/v1/ume/client/user/line/login/web`, {
    method: 'POST',
    data
  })
}

/** 登出 */
export function queryUserLoginOut() {
  return request(`/v1/ume/client/user/logout`, { method: 'POST' })
}

/** 根據token獲取用戶信息 */
export function queryUserByToken() {
  return request(`/v1/ume/client/user/getUserByToken`)
}

/** 補充手機號 */
export function queryUserFillPhone(data: IApiParam.FillPhone) {
  return request(`/v1/ume/client/user/fill/mobileNumber`, { method: 'POST', data })
}

/** 獲取會員碼 */
export function queryUserMemberNo(userId: string) {
  return request(`/v1/ume/client/user/member/code/${userId}`)
}