import React, { useMemo } from 'react';
import { isEmpty } from '@/utils/common';
import styles from './Price.less';

interface IPrice {
  price: string | number
  discount?: boolean
  del?: boolean
  className?: string
}

const defuatProps = {
  price: 0,
  className: ''
}

const Price: React.FC<IPrice> = (props = defuatProps) => {
  const realPrice = useMemo(() => {
    const { price = '' } = props;
    if (isEmpty(price)) {
      return '0';
    }
    if (price === '-') return price;
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }, [props.price]);

  return (
    <span className={`${styles.price} ${props.del ? styles.del : ''} ${props.className}`}>
      {!props.discount && <i>NT$</i>}
      <span>{realPrice}</span>
      {props.discount && <i>折</i>}
    </span>
  );
};

export default Price;
