import { isEmpty } from './common';

const Tab_Routes = ['/store', '/mine']
let route_history = [''];

const routeFn = {
  get(): any[] {
    let routes = localStorage.getItem('u_routes');
    return !isEmpty(routes) ? JSON.parse(routes || '[]') : []
  },
  set(routes: any[]) {
    route_history = routes
    localStorage.setItem('u_routes', JSON.stringify(routes))
  },
  pathKey(path: string): string {
    let strs = path.match(/^(\S*)[\#\?]?/) || []
    return strs[1] || ''
  }
}

function push(path: string) {
  // 下一页
  // let pathKey = routeFn.pathKey(path)
  let routes = routeFn.get();
  if (isEmpty(routes)) {
    routes = [`/store/${localStorage.getItem('braId')}`]
  }
  routes.push(path);
  routeFn.set(routes);
  (window as any).realHistory.push(path, {
    anim: 'rightIn'
  })
}

function replace(path: string, anim?: string) {
  let routes = routeFn.get();
  // let pathKey = routeFn.pathKey(path)
  routes.splice(routes.length - 1, 1);
  if (isEmpty(routes)) {
    routes = [`/store/${localStorage.getItem('braId')}`]
  }
  routes.push(path);
  routeFn.set(routes);
  (window as any).realHistory.replace(path, {
    anim: anim ?? 'rightIn'
  })
}

function goBack(step: number | any = 1) {
  if (typeof step !== 'number') step = 1;
  let routes = routeFn.get();
  if (isEmpty(routes) || routes.length < 2) {
    routes = []
    routes.push(`/store/${localStorage.getItem('braId')}`);
    (window as any).realHistory.push(`/store/${localStorage.getItem('braId')}`, {
      anim: 'leftIn'
    })
  } else {
    if (routes.length <= step) {
      step = routes.length - 1
    }
    let path = routes[routes.length - (step + 1)];
    routes.splice(routes.length - step, step);
    (window as any).realHistory.push(path, {
      anim: 'leftIn'
    })
  }
  // console.log(history.location)
  // console.log(window.location)
  routeFn.set(routes)
}

function switchTo(path: string) {
  let routes = routeFn.get();
  let pathKey = routeFn.pathKey(path)
  let anim = 'rightIn';
  if (routes.length > 1) {
    anim = 'leftIn'
  } else {
    anim = Tab_Routes.indexOf(pathKey) < Tab_Routes.indexOf(routes[0]) ? 'leftIn' : 'rightIn'
  }
  routes = []
  routes.push(path);
  routeFn.set(routes);
  (window as any).realHistory.push(path, {
    anim
  })
}

function checkRoute() {
  let routes = routeFn.get();
  let winPath = window.location.pathname;
  // console.log("🚀 ~ file: route.ts ~ line 90 ~ checkRoute ~ routes.indexOf(winPath)", routes.indexOf(winPath))
  if (routes.indexOf(winPath) < 0) {
    // 瀏覽器前進
    routes.push(winPath)
  } else {
    // 瀏覽器後退
    routes.splice(routes.length - 1, 1);
  }
  routeFn.set(routes)
  // console.log('------------------------------')
  // console.log('瀏覽器的當前頁', window.location.pathname)
  // console.log('history的當前頁', history.location.pathname)
  // console.log('routes的當前頁', routes)
}

function clear() {
  routeFn.set([`/store/${localStorage.getItem('braId')}`])
}

export const umeHistory = {
  push,
  replace,
  goBack,
  switchTo,
  checkRoute,
  clear
}