import React from 'react';
import dva from 'dva';
import createLoading from 'dva-loading';
import { history } from './route/history';
import Routes from '@/route/routes';
import './style/global.less';
import 'react-photoswipe/lib/photoswipe.css';
import * as serviceWorker from './serviceWorker';
import Layout from './wrapper/layout/layout';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { BrowserRouter } from 'react-router-dom';
import 'moment/locale/zh-tw';
import moment from 'moment';
import { Loader } from "@googlemaps/js-api-loader"

moment.locale('zh-tw')

const loader = new Loader({
  apiKey: "AIzaSyBgTi8gPENB6yFesZL29uzGwVl0CsI2J4k",
  libraries: ["places"],
  version: "weekly",
});

window.googleLoader = loader;
loader.load()

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes />
      </Layout>
    </BrowserRouter>
  );
};

const app = dva({
  history,
  onReducer(reducer: any) {
    /* 数据持久化配置 */
    const persistConfig = {
      key: 'root',
      storage,
      stateReconciler: autoMergeLevel2,
      whitelist: ['store', 'user', 'location'],
    };
    return persistReducer(persistConfig, reducer);
  },
});
app.use(createLoading())
app.model(require('@/models/store.ts').default)
app.model(require('@/models/user.ts').default)
app.model(require('@/models/location.ts').default)
app.router(() =>
  <App />
)
app.start('#root')

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();