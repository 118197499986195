import React from 'react';
import styles from './Spinning.less';

interface ISpinning {
  type: 'colors' | 'bounce' | 'circle';
  size?: number;
  state?: 'light' | 'dark' | 'white';
  className?: string;
};

const defaultProps = {
  type: 'circle',
  state: 'dark'
}

const Spinning = (props: ISpinning) => {
  switch (props.type) {
    case 'colors':
      return (
        <div
          className={`${styles.loader_colors} ${props.className}`}
          style={props.size ? { fontSize: `${props.size / 100}rem` } : {}}
        ></div>
      );
    case 'circle':
      return (
        <div
          className={`${styles.loader_circle} ${styles[props.state || 'dark']} ${props.className}`}
          style={props.size ? { fontSize: `${props.size / 100}rem` } : {}}
        ></div>
      );
    case 'bounce':
      return (
        <div
          className={`${styles.loader_bounce} ${styles[props.state || 'dark']} ${props.className}`}
          style={props.size ? { fontSize: `${props.size / 100}rem` } : {}}
        >
          <div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      );
    default:
      return null;
  }
};

Spinning.defaultProps = defaultProps

export default Spinning;
