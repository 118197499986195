export default {
  namespace: 'location',
  state: {
    latlng: {},
    cityName: [],
    location: null,
  },
  effects: {

  },
  reducers: {
    saveLocationInfo(state: IModelLocation, { payload }: IDvaAction) {
      return {
        ...state,
        ...payload
      }
    },
  }
}