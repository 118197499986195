import { isEmpty } from '@/utils/common';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import styles from './ActionSheet.less';

type IActionSheet = {
  content?: ReactNode | string
  list?: any[]
  onSelect?: (res?: any) => void
  onCancle?: () => void
  valueKey?: string
  renderRow?: (item: any, index: number) => ReactNode
  maskClose?: boolean
  cancelText?: string | ReactNode
};
/**
 * 底部操作面板
 *
 * @param
 * - 参数
 * - list 数据数组
 * - onSelect 选中回调 参数：item
 * - onCancle 取消回调
 * - valueKey 需要显示的字段名 默认value
 * - renderRow 自定义渲染 参数：item，index
 */

function show(params: IActionSheet) {
  const { list, onSelect, valueKey, onCancle, renderRow, maskClose, content, cancelText } = params;
  let actionWrap = document.createElement('div');
  document.body.appendChild(actionWrap);

  /* 渲染节点 */
  const renderItem = (item: any, index: number) => {
    return (
      <div
        key={item[valueKey || 'value'] || item}
        className={styles.action_item}
        onClick={() => {
          onSelect && onSelect(item);
        }}
      >
        {renderRow ? renderRow(item, index) : item[valueKey || 'value'] || item}
      </div>
    );
  };

  /* 关闭窗口 */
  const closeSheet = () => {
    onCancle && onCancle();
    actionWrap.className = styles.action_hide;
    setTimeout(() => {
      if (actionWrap) {
        try {
          ReactDOM.unmountComponentAtNode(actionWrap);
          document.body.removeChild(actionWrap);
        } catch (err) {
          // console.log("🚀 ~ file: ActionSheet.tsx ~ line 60 ~ setTimeout ~ err", err)
        }
      }
    }, 600);
  };

  ReactDOM.render(
    <>
      <div
        className={styles.action_mask}
        onClick={event => {
          maskClose && closeSheet()
          event.stopPropagation();
        }}
      ></div>
      <div className={styles.action_wrap}>
        {content && content}
        {!content && <div className={styles.action_list}>
          {!isEmpty(list) && list && list.map(renderItem)}
        </div>}
        <button
          type="button"
          className={styles.action_cancle}
          onClick={closeSheet}
        >
          {cancelText ? cancelText : '取消'}
        </button>
      </div>
    </>,
    actionWrap,
  );
  return {
    hide: () => {
      closeSheet();
    },
  };
}

export default {
  show,
};
