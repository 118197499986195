import React, { Suspense, lazy } from 'react'
import AsyncLoading from '@/components/AsyncLoading/AsyncLoading';
import AuthWrapper from '@/wrapper/auth/auth';

function WaitingComponent(Component: React.FC, Wrapper?: React.FC): React.FC {
  return (props: any) => (
    <Suspense fallback={<AsyncLoading />}>
      {Wrapper ? <Wrapper {...props}>
        <Component {...props} />
      </Wrapper> : <Component {...props} />}
    </Suspense>
  );
}


export default [{
  key: 'storeAuth',
  exact: true,
  path: '/store',
  redirect: `/store/${localStorage.getItem('braId') || undefined}`,
}, {
  key: 'storeComments',
  exact: true,
  path: '/store/comments',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_store_commentList' */`@/pages/store/commentList`))),
}, {
  key: 'commentComplaint',
  exact: true,
  path: '/comment/complaint',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_commentDetail_complaint' */`@/pages/commentDetail/complaint`)), AuthWrapper),
}, {
  key: 'commentDetail',
  exact: true,
  path: '/comment/:platform/:commentId',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_commentDetail' */`@/pages/commentDetail/commentDetail`))),
}, {
  key: 'store',
  exact: true,
  path: '/store/:braId',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_store' */`@/pages/store/store`))),
}, {
  key: 'iframe',
  exact: true,
  path: '/iframe/:platform',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_iframe' */`@/pages/iframe/iframe`))),
}, {
  key: 'viewlabel',
  exact: true,
  path: '/viewlabel',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_viewlabel' */`@/pages/viewlabel/viewlabel`))),
}, {
  key: 'store_fnb',
  exact: true,
  path: '/store/:braId/:codeId',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_store' */`@/pages/store/store`))),
}, {
  key: 'jump_pos',
  exact: true,
  path: '/jump/:braId',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_store_jump' */`@/pages/store/jump`))),
}, {
  key: 'jump_fnb',
  exact: true,
  path: '/jump/:braId/:codeId',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_store_jump' */`@/pages/store/jump`))),
}, {
  key: 'jump',
  exact: true,
  path: '/jump',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_store_jump' */`@/pages/store/jump`))),
}, {
  key: 'signin',
  exact: true,
  path: '/signin',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_sign_signin' */`@/pages/sign/signin`))),
}, {
  key: 'captcha',
  exact: true,
  path: '/captcha',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_sign_captcha' */`@/pages/sign/captcha`))),
}, {
  key: 'third',
  exact: true,
  path: '/third/:platform',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_sign_third' */`@/pages/sign/third`))),
}, {
  key: 'location',
  exact: true,
  path: '/location',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_location' */`@/pages/location/location`))),
}, {
  key: 'discover',
  exact: true,
  path: '/',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_discover' */`@/pages/discover/discover`))),
}, {
  key: 'discover_dailybest',
  exact: true,
  path: '/discover/dailybest',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_discover_dailybest' */`@/pages/discover/dailybest`))),
}, {
  key: 'discover_coupons',
  exact: true,
  path: '/discover/coupons',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_discover_coupons' */`@/pages/discover/coupons`))),
}, {
  key: 'discover_search',
  exact: true,
  path: '/discover/search',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_discover_search' */`@/pages/discover/search`))),
}, {
  key: 'phone',
  exact: true,
  path: '/phone',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_sign_phone' */`@/pages/sign/phone`)), AuthWrapper),
}, {
  key: 'mine',
  exact: true,
  path: '/mine',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_mine' */`@/pages/mine/mine`)), AuthWrapper),
}, {
  key: 'mine_info',
  exact: true,
  path: '/mine/info',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_mine_info' */`@/pages/mine/info`)), AuthWrapper),
}, {
  key: 'mineCoupon',
  exact: true,
  path: '/mineCoupon',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_mineCoupon' */`@/pages/mineCoupon/mineCoupon`)), AuthWrapper),
}, {
  key: 'storeCollect',
  exact: true,
  path: '/storeCollect',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_storeCollect' */`@/pages/storeCollect/storeCollect`)), AuthWrapper),
}, {
  key: 'order',
  exact: true,
  path: '/order/:platform',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_order' */`@/pages/order/order`)), AuthWrapper),
}, {
  key: 'suggest',
  exact: true,
  path: '/suggest',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_suggest' */`@/pages/suggest/suggest`)), AuthWrapper),
}, {
  key: 'notice_system',
  exact: true,
  path: '/notice/system',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_notice_system' */`@/pages/notice/system`)), AuthWrapper),
}, {
  key: 'notice_store_detail',
  exact: true,
  path: '/notice/store/:braId',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_notice_store_detail' */`@/pages/notice/storeDetail`)), AuthWrapper),
}, {
  key: 'notice_like',
  exact: true,
  path: '/notice/like',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_notice_like' */`@/pages/notice/likeList`)), AuthWrapper),
}, {
  key: 'notice_reply',
  exact: true,
  path: '/notice/reply',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_notice_reply' */`@/pages/notice/replyList`)), AuthWrapper),
}, {
  key: 'notice',
  exact: true,
  path: '/notice/:section',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_notice' */`@/pages/notice/notice`)), AuthWrapper),
}, {
  key: 'uncomment_complain',
  exact: true,
  path: '/uncomment/complain',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_uncomment_complain' */`@/pages/uncomment/complain`)), AuthWrapper),
}, {
  key: 'uncomment',
  exact: true,
  path: '/uncomment/:platform',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_uncomment' */`@/pages/uncomment/uncomment`)), AuthWrapper),
}, {
  key: 'address',
  exact: true,
  path: '/address',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_address' */`@/pages/address/address`)), AuthWrapper),
}, {
  key: 'address_edit',
  exact: true,
  path: '/address/:addressId',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_address_edit' */`@/pages/address/edit`)), AuthWrapper),
}, {
  key: 'address_seven_eleven',
  exact: true,
  path: '/address-edit',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_address_seven_eleven' */`@/pages/address/sevenEleven`)), AuthWrapper),
}, {
  key: 'invoice',
  exact: true,
  path: '/invoice',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_address_invoice' */`@/pages/invoice/invoice`)), AuthWrapper),
}, {
  key: 'checkin_record',
  exact: true,
  path: '/checkin/record',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_checkin_record' */`@/pages/checkin/record`)), AuthWrapper),
}, {
  key: 'gift_coupon',
  exact: true,
  path: '/gift/coupon/:couponId',
  component: WaitingComponent(lazy(() => import(/* webpackChunkName: 'page_gift_coupon' */`@/pages/gift/coupon`))),
}]