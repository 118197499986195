import React, { useState } from 'react';
import styles from './Rate.less';

interface IRate {
  level: number
  type?: 'star' | 'circle'
  disabled?: boolean
  className?: string
  onChange?: (level: number) => void
  small?: boolean
}

export default function (props: IRate) {

  const { disabled = false } = props;

  let levels: Array<number> = [1, 2, 3, 4, 5];
  const [star, setStar] = useState(props.level || 5);

  const handleChangeStar = (num: number) => {
    if (disabled) return;
    setStar(num);
    props.onChange && props.onChange(num);
  }

  const exchangeStatus = (item: number) => {
    let intVal = Math.ceil(star);
    let decVal = star % 1;
    if (item < intVal) {
      return styles.active;
    } else if (item === intVal) {
      if (decVal === 0) {
        return styles.active;
      } else if (decVal > 0.4) {
        return styles.half;
      } else {
        return '';
      }
    } else {
      return ''
    }
  }

  return <span className={`${styles.rate_wrap} ${props.small && styles.small} ${props.className}`}>
    {levels.map((item, index) => (
      <span
        key={index}
        className={`${styles[/* props.type || */'star']} ${exchangeStatus(item)}`}
        onClick={() => { handleChangeStar(item) }}
      >{item}星</span>
    ))}
  </span>
}