import { umeHistory } from '@/utils/route'
import React from 'react'
import styles from './exception.less';

export default function () {
  return <div className={styles.unfind}>
    <i className={styles.icon}></i>
    <h3>抱歉，</h3>
    <p>您訪問的頁面不存在。</p>
    <ul className={styles.tips}>
      <li>請重新掃碼進入</li>
      <li>或者，您訪問了無效的頁面路徑</li>
    </ul>
    <button type="button" onClick={() => { umeHistory.replace(`/store/${localStorage.getItem('braId')}`) }}>返回首頁</button>
  </div>
}