import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route, Switch, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import routeData from './config';
import NotFound from '@/pages/exception/404';
import { parse } from 'qs';
import { isEmpty } from '@/utils/common';

interface IRouterItem {
  key?: string;
  component?: React.FC;
  path: string;
  exact?: boolean;
  wrapper?: any;
  redirect?: any;
}

const ANIMATION_MAP: any = {
  rightIn: 'forward',
  leftIn: 'back'
}

function exchangeSearch(search: any) {
  if (isEmpty(search)) return {};
  let searchStr = search.substr(1);
  return parse(searchStr)
}

class Routes extends React.Component<any> {
  render() {
    const { location, history } = this.props;
    // console.log("Routes -> render -> history", history);
    (window as any).realHistory = history
    return (
      <TransitionGroup
        className={'router-wrapper'}
        childFactory={(child: any) => React.cloneElement(
          child,
          { classNames: ANIMATION_MAP[history.action === 'POP' ? 'leftIn' : (history.location?.state?.anim || '')] }
        )}
      >
        <CSSTransition
          appear={true}
          key={history.location.pathname}
          timeout={400}
          unmountOnExit={true}
        >
          <div className="router-page">
            <Switch location={{ ...location, query: exchangeSearch(location.search) }}>
              {routeData.map(({ path, component, exact, redirect }: IRouterItem) => (
                redirect
                  ? <Redirect key={path} from={path} exact={exact} to={redirect} />
                  : <Route key={path} path={path} exact={exact} component={component} />
              ))}
              <Route component={NotFound} />
            </Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default withRouter(Routes);