import { FNB_PATH, PIC_PATH, POS_PATH, IS_PRE_PUSH, APP_DOWNLOAD } from '@/configs/config';
import { Message } from '@/design';
import { stringify } from 'qs';
import { isEmpty, JsonStorge } from './common';
import { umeHistory } from './route';

/** 跳轉零售H5頁面路徑 */
export function linkPosPath(type: 'store' | 'cashier' | 'goods' | 'uncomment' | 'order', params: any) {
  let path = '';
  switch (type) {
    case 'store': path = `${POS_PATH}/portal/store?${stringify(params)}`; break;
    case 'cashier': path = `${POS_PATH}/portal/cashier?${stringify(params)}`; break;
    case 'goods': path = `${POS_PATH}/portal/goods?${stringify(params)}`; break;
    case 'uncomment': path = `${POS_PATH}/portal/uncomment?${stringify(params)}`; break;
    case 'order': path = `${POS_PATH}/portal/order?${stringify(params)}`; break;
    default: break;
  }
  let userInfo = JsonStorge.get('userInfo');
  if (!isEmpty(userInfo)) {
    path = `${path}&token=${userInfo.token}`
  }
  if (IS_PRE_PUSH) {
    path = `${path}&version=TL_stage`
  }
  if (window.location.origin.indexOf('portal2') > -1) {
    path = path.replace('pass.', 'pass1.')
    path = `${path}&source=p_zh`
  }
  // sessionStorage.setItem('jumplink', path);
  localStorage.setItem('isPageHide', 'Y')
  window.location.href = path
  // umeHistory.push('/iframe/pos')
}

/** 跳轉餐飲H5頁面路徑 */
export function linkFnbPath(type: 'home' | 'uncomment' | 'order', params: any) {
  let path = '';
  switch (type) {
    case 'home': path = `${FNB_PATH}/app/?${stringify(params)}`; break;
    case 'order': path = `${FNB_PATH}/app/?${stringify(params)}`; break;
    case 'uncomment': path = `${FNB_PATH}/app/?${stringify(params)}`; break;
    default: break;
  }
  let userInfo = JsonStorge.get('userInfo');
  if (!isEmpty(userInfo)) {
    path = `${path}&token=${userInfo.token}`
  }
  if (IS_PRE_PUSH) {
    path = `${path}&version=TL_stage`
  }
  if (window.location.origin.indexOf('portal2') > -1) {
    path = `${path}&source=p_zh`
  }
  // sessionStorage.setItem('jumplink', path);
  localStorage.setItem('isPageHide', 'Y')
  window.location.href = path
  // umeHistory.push('/iframe/fnb')
}

/** 跳轉其它頁面 */
export function linkOtherPath(path: string) {
  let pathKey = 'other';
  if (path.indexOf('pass') > -1) {
    pathKey = 'pos'
  } else if (path.indexOf('fnb') > -1) {
    pathKey = 'fnb'
  }
  // sessionStorage.setItem('jumplink', path);
  localStorage.setItem('isPageHide', 'Y')
  window.location.href = path
  // umeHistory.push(`/iframe/${pathKey}`)
}

/** 獲取默認頭像 */
export function getDefaultAvator(sex?: 'male' | 'female') {
  switch (sex) {
    case 'male': return require('@/assets/images/avator_male.png');
    case 'female': return require('@/assets/images/avator_female.png');
    default: return undefined
  }
}

/** 獲取ume圖片全路徑 */
export function getUmePicPath(name: string, size?: 300 | 600): string {
  if (!size) size = 300;
  if (isEmpty(name)) return '';
  if (name.indexOf('/300') > -1) {
    let sizeIndex = name.indexOf('/300');
    sizeIndex = sizeIndex < 0 ? name.indexOf('/600') : sizeIndex;
    name = name.substring(0, sizeIndex);
    return `${name}/${size}`;
  }
  if (name.indexOf('?filename') > -1) return `${name}/${size}`;
  return `${PIC_PATH}/v1/ume/client/file/showImage?filename=${name}/${size || 300}`
}

/** 獲取當前定位 */
export function getLocationByH5() {
  return new Promise<any>((resolve) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        let { latitude, longitude } = position.coords
        console.log("🚀 ~ file: business.ts ~ line 58 ~ navigator.geolocation.getCurrentPosition ~ latitude, longitude", latitude, longitude)
        longitude = longitude.toString().match(/\d+\.\d{0,6}/)[0]
        latitude = latitude.toString().match(/\d+\.\d{0,6}/)[0]
        resolve([latitude, longitude]);
      }, () => {
        resolve(false)
        Message.warn('定位失敗了~')
      }, {
        timeout: 3000
      });
    } else {
      Message.warn('抱歉，您的瀏覽器不支持定位服務')
      resolve(false)
    }
  })
}

/** 根據經緯度獲取谷歌位置對象 */
interface ILocationByGeocoder {
  location?: string[]
  address?: string
  placeId?: string
}
export function getLocationByGeocoder(params: ILocationByGeocoder) {
  return new Promise<any>(async (resolve) => {
    if (!window.google) {
      await window.googleLoader && window.googleLoader.load();
    }
    let google = window.google;
    if (!google) {
      resolve(false);
      return false
    };
    let geocoder = new google.maps.Geocoder();
    if (params['location'] && params['location'].length > 0) {
      params['location'] = new google.maps.LatLng(...params['location'])
    }
    geocoder.geocode(params, (result: any) => {
      if (isEmpty(result)) {
        resolve(false);
        return false
      };
      resolve(result[0])
    })
  })
}

/** 转换Google位置对象 */
export function exchangeGoogleLocation(location: any) {
  const { address_components, formatted_address, geometry } = location
  // console.log("🚀 ~ file: business.ts ~ line 121 ~ exchangeGoogleLocation ~ geometry", geometry)
  let cityName = ['', ''];
  if (!isEmpty(address_components)) {
    address_components.forEach((element: any) => {
      switch (element.types[0]) {
        case 'administrative_area_level_1': cityName[0] = element.short_name; break;
        case 'administrative_area_level_2': cityName[0] = element.short_name; break;
        case 'administrative_area_level_3': cityName[1] = element.short_name; break;
        case 'locality': cityName[1] = element.short_name; break;
        default: break;
      }
    });
  }
  if (cityName && cityName[0] === '') {
    cityName = ['台北市']
  }
  return {
    cityName,
    address: formatted_address,
    latlng: {
      latitude: geometry?.location.lat() || '25.027615',
      longitude: geometry?.location.lng() || '121.566793',
    }
  }
}

/** 谷歌导航 */
export async function googleNavigation(position: string) {
  if (!window?.google && !window.google?.map) {
    Message.warn('您的浏览器不支持位置服务');
    return false
  }
  let currentLoc = await getLocationByH5();
  if (!currentLoc) return false;
  currentLoc = currentLoc.join(',')
  // currentLoc = '24.2327271,120.6850841'
  window.open(`https://www.google.com/maps/dir/${currentLoc}/${position}`)
  // console.log("🚀 ~ file: business.ts ~ line 67 ~ googleNavigation ~ currentLoc", currentLoc)
}

/** 喚起APP */
export function callUmeApp(schemes: string) {
  if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
    let loadDateTime = new Date().getTime();
    window.setTimeout(function () {
      let timeOutDateTime = new Date().getTime();
      if (timeOutDateTime - loadDateTime < 5000) {
        window.location.href = APP_DOWNLOAD;
      } else {
        window.close();
      }
    },
      25);
    window.location.href = schemes;
  } else if (navigator.userAgent.match(/android/i)) {
    let state = null;
    try {
      state = window.open(schemes, '_blank');
    } catch (e) {
      window.location.href = schemes;
    }
    if (state) {
      window.close();
    } else {
      window.location.href = APP_DOWNLOAD;
    }
  }
}

/** 与ios通信 */
export function initUmePlatform() {
  // 调取ios方法
  (window as any)?.webkit
    && (window as any).webkit?.messageHandlers
    && (window as any).webkit.messageHandlers?.getUserPlatform
    && (window as any).webkit.messageHandlers.getUserPlatform.postMessage(null);
}

/** 判斷是否UmeAPP打開 */
export function isOpenByUmeAPP() {
  return (window as any).umeApp && (window as any).umeApp.getUserPlatform() === 'umeApp';
}

/** GA設置userID */
export function Ga_User(userID: string) {
  window.gtag('set', { 'user_id': userID || '' });
}

/** GA埋點 */
export function Ga_Send(eventCategory: string, eventAction: string, eventLabel?: string, eventValue?: number) {
  // 接口埋點
  window['ga'] && window.ga && window.ga('send', {
    hitType: 'event',
    eventCategory,
    eventAction,
    eventLabel,
    eventValue
  });
}