import { isEmpty } from '@/utils/common';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import styles from './DatePicker.less';
import PickerScroll from './PickerScroll';

interface IDDatePicker {
  defaultValue: string
  format?: 'YYYY-MM-DD HH:mm:ss' | 'YYYY-MM-DD' | 'HH:mm:ss' | string
  onSelect: (res: string) => (Promise<any> | void)
}

let nodeWrap: HTMLDivElement;

/* 初始化外部容器 */
const initWrap = () => {
  if (!nodeWrap) {
    nodeWrap = document.createElement('div');
    document.body.appendChild(nodeWrap);
  }
  // nodeWrap.className = styles.node_wrap;
  const nodeBox = document.createElement('div');
  nodeWrap.appendChild(nodeBox);
  return nodeBox;
};

interface IDDMain extends IDDatePicker {
  type: 'datetime' | 'date' | 'time'
  wrapNode: HTMLDivElement
  elemNode: Element | DocumentFragment
  placeholder: string
}

/** 判斷是否為promise */
function isPromise(obj: any) {

  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';

}

const NodeMain: React.FC<IDDMain> = props => {
  let unmounted = false;
  let init = false;
  const { defaultValue, format, onSelect } = props;
  const { elemNode, wrapNode, type, placeholder } = props;
  let defautMoment = !isEmpty(defaultValue) ? moment(defaultValue, format) : moment()

  const [year, setYear] = useState<number>(defautMoment ? defautMoment.year() : 2020)
  const [month, setMonth] = useState<number>(defautMoment ? defautMoment.month() + 1 : 1)
  const [day, setDay] = useState<number>(defautMoment ? defautMoment.date() : 1)
  const [hour, setHour] = useState<string>(defautMoment ? defautMoment.format('HH') : '12')
  const [minute, setMinute] = useState<string>(defautMoment ? defautMoment.format('mm') : '00')
  const [second, setSecond] = useState<string>(defautMoment ? defautMoment.format('ss') : '00')

  // 卸载
  const unmount = useMemo(() => {
    return () => {
      if (elemNode && wrapNode && init) {
        unmounted = true
        unmountComponentAtNode(elemNode);
        wrapNode.removeChild(elemNode);
      }
    };
  }, [elemNode, wrapNode]);

  // 创建年份
  const createYearData = () => {
    let years: Array<number> = [];
    for (let i = 1950; i <= 2050; i++) {
      years.push(i);
    }
    return years
  }

  // 创建月份
  const createMonthData = () => {
    let months: Array<number> = [];
    for (let i = 1; i <= 12; i++) {
      months.push(i);
    }
    return months
  }

  // 创建天数
  const createDayData = useMemo(() => {
    let dateObj = moment(`${year}/${month}`, 'YYYY-M').daysInMonth();
    let days: Array<number> = [];
    for (let i = 1; i <= dateObj; i++) {
      days.push(i);
    }
    if (day > dateObj) {
      setDay(1)
    }
    return days
  }, [month, year])

  // 创建小时数
  const createHourData = () => {
    let hours: Array<any> = [];
    for (let i = 0; i <= 23; i++) {
      hours.push(i < 10 ? `0${i}` : i);
    }
    return hours
  }

  // 创建分/秒数
  const createMsData = () => {
    let mss: Array<any> = [];
    for (let i = 0; i <= 59; i++) {
      mss.push(i < 10 ? `0${i}` : i);
    }
    return mss
  }

  // 确认
  const handleConfirm = () => {
    init = true
    let momentEl = moment(`${year}/${month}/${day} ${hour}:${minute}:${second}`);
    if (!onSelect) {
      unmount();
      return false;
    }
    let confirmFn: any = onSelect(momentEl.format(format));
    if (isPromise(confirmFn)) {
      confirmFn.then((res: boolean) => {
        res && unmount();
      })
    } else {
      unmount()
    }
  }

  useEffect(() => {
    setTimeout(() => {
      init = true
    }, 300);
  }, [])

  return (
    <>
      <div className={styles.node_mask} onClick={unmount}></div>
      <div className={styles.node_main}>
        <div className={styles.node_head}>
          <h3>{placeholder}</h3>
          <button className={styles.confirm} onClick={handleConfirm}>確認</button>
        </div>
        <div className={styles.node_box}>
          {
            type !== 'time' && <>
              <PickerScroll type="year" data={createYearData()} onScroll={(res) => { setYear(res) }} current={year} />
              <PickerScroll type="month" data={createMonthData()} onScroll={(res) => { setMonth(res) }} current={month} />
              <PickerScroll type="day" data={createDayData} onScroll={(res) => { setDay(res) }} current={day} />
            </>
          }
          {
            type !== 'date' && <>
              <PickerScroll type="hour" data={createHourData()} onScroll={(res) => { setHour(res) }} current={hour} />
              <PickerScroll type="minute" data={createMsData()} onScroll={(res) => { setMinute(res) }} current={minute} />
              <PickerScroll type="second" data={createMsData()} onScroll={(res) => { setSecond(res) }} current={second} />
            </>
          }
        </div>
      </div>
    </>
  );
};

/* 类型 - 時間日期 */
const datetime = (params: IDDatePicker) => {
  let elemNode = initWrap();
  // nodeWrap.className = styles.message_box;
  ReactDOM.render(
    <NodeMain
      format="YYYY-MM-DD HH:mm:ss"
      placeholder="請選擇時間日期"
      type="datetime"
      elemNode={elemNode}
      wrapNode={nodeWrap}
      {...params}
    />,
    elemNode,
  );
};

/* 类型 - 日期 */
const date = (params: IDDatePicker) => {
  let elemNode = initWrap();
  // nodeWrap.className = styles.message_box;
  ReactDOM.render(
    <NodeMain
      format="YYYY-MM-DD"
      placeholder="請選擇日期"
      type="date"
      elemNode={elemNode}
      wrapNode={nodeWrap}
      {...params}
    />,
    elemNode,
  );
};

/* 类型 - 時間 */
const time = (params: IDDatePicker) => {
  let elemNode = initWrap();
  // nodeWrap.className = styles.message_box;
  ReactDOM.render(
    <NodeMain
      format="HH:mm:ss"
      placeholder="請選擇時間"
      type="time"
      elemNode={elemNode}
      wrapNode={nodeWrap}
      {...params}
    />,
    elemNode,
  );
};

export default {
  datetime,
  date,
  time
}