import React, { useEffect, useRef, useState } from 'react'
import BScroll, { Options } from '@better-scroll/core';
import ObserveDOM from '@better-scroll/observe-dom'
import styles from './DatePicker.less';

BScroll.use(ObserveDOM as any);

interface Position {
  x: number;
  y: number;
};

interface IDPickerScroll {
  onScroll: (res: any) => void
  data: Array<number>
  current?: number | string
  type: string
}

const defaultConfig: Options = {
  probeType: 3,
  observeDOM: true,
  scrollY: true,
  stopPropagation: true,
};

const PickerScroll: React.FC<IDPickerScroll> = props => {

  const scrollRef = useRef<BScroll>();
  const wrapRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);
  const dataRef = useRef(props.data)

  /* 初始化滾動 */
  const initScroll = () => {
    // console.log("🚀 ~ file: Scroll.tsx ~ line 56 ~ initScroll ~ newConfig", newConfig)
    let bs: BScroll = new BScroll(wrapRef.current as HTMLDivElement, defaultConfig);

    // 滾動監聽
    bs.on('scrollEnd', (pos: Position) => {
      let LH = -Number(itemRef.current?.getBoundingClientRect().height);
      let realIndex: number = Math.round(pos.y / LH)
      bs.scrollTo(0, realIndex * LH, 200)
      props.onScroll && props.onScroll(dataRef.current[realIndex])
    })
    // console.log(document.querySelector(`#${props.type}_${props.current}`))

    props.current && bs.scrollToElement(`#${props.type}_${props.current}`, 0, false, true)

    scrollRef.current = bs;
  };

  useEffect(() => {
    scrollRef.current?.refresh();
    dataRef.current = props.data
    props.current && scrollRef.current && scrollRef.current.scrollToElement(`#${props.type}_${props.current}`, 0, false, true)

  }, [props.data])

  useEffect(() => {
    setTimeout(() => {
      initScroll();
    }, 0);
    return () => {
      scrollRef.current && scrollRef.current.destroy()
    }
  }, [])

  return <div ref={wrapRef} className={styles.picker_box} key={props.type}>
    <div>
      <div ref={itemRef}></div>
      <div></div>
      <div></div>
      {props.data && props.data.map((item, index) => <div key={`${props.type}_${item}_${index}`} id={`${props.type}_${item}`}>{item}</div>)}
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
}

export default PickerScroll