import React from "react";
import { useState, useEffect } from "react";

export default function (props: any) {

  let unmount = false;

  const [styles, setStyles] = useState<React.CSSProperties>({
    position: 'fixed'
  });

  const windheight = window.innerHeight;  /*未唤起键盘时当前窗口高度*/

  const handleResize = () => {
    const docheight = window.innerHeight; /*唤起键盘时当前窗口高度*/
    setStyles(docheight < windheight ? {
      position: 'static',
      display: 'none'
    } : { position: 'fixed' });
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize);
      unmount = true;
    }
  }, [])

  return <div className={props.className} style={styles}>{props.children}</div>
}