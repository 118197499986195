import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { isEmpty } from '@/utils/common'
import { Message } from '@/design';

function checkStatus(response: AxiosResponse) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 401) {
    localStorage.removeItem('userInfo')
  }

  const error: any = new Error(response.statusText);
  error.response = response;
  throw error;
}

interface IResponseResult {
  code?: number
  message?: string
  data?: any
  list?: Array<any>
  size: number
  total: number
  [key: string]: any
}

export const request = (url: string, options?: AxiosRequestConfig): Promise<IResponseResult> => {
  // console.log(1)
  let userInfo: any = localStorage.getItem('userInfo');
  if (isEmpty(userInfo)) {
    // 如果用户信息为空
    userInfo = '{}'
  }
  const { token } = JSON.parse(userInfo);

  let axiosConfig: AxiosRequestConfig = {
    url,
    method: 'GET',
    timeout: 20000,
    ...options,
    headers: {
      'Content-Type': 'application/json',
      'TL-Origin': 'ume-web',
      'Accept-Language': 'zh-TW',
      'Request-Tk': token || '',
      // 'braId': localStorage.getItem('braId') || undefined,
      // 'umePassUserId': userId,
      ...(options?.headers || {})
    },
  };

  /* 发起请求 */
  return axios(axiosConfig).then(checkStatus).then(res => {
    // console.log(res)
    const { code, message } = res.data;
    if (code !== 200) {
      if (code === 401) {
        localStorage.removeItem('userInfo')
        // request(url, options)
        Message.error({ content: message, onClose: () => { window.location.reload() } })
      } else {
        Message.error({ content: message })
      }
      return false
    } else {
      return res.data
    }
  }).catch(err => {
    Message.error({ content: `網路不太穩，再試一次` })
    console.log(err)
  })

}