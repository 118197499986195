import { request } from '@/utils/request';
import { stringify } from 'qs';

/** 查詢門店信息 */
export function queryStoreInfo(params: any) {
  const { braId, codeId } = params;
  return request(`/v1/ume/client/store/home/${braId}${codeId ? `/${codeId}` : ''}`)
}

/** 查詢是否是商戶會員 */
export function queryIsMerMember(params: IApiParam.IsMerMember) {
  return request(`/v1/ume/client/member/is/mer?${stringify(params)}`)
}

/** 查詢店鋪推薦商品 */
export function queryGoodsRecommend(params: IApiParam.GoodsRecommend) {
  return request(`/v1/ume/client/store/goods/recommend?${stringify(params)}`)
}

/** 查詢零售門店評論 */
export function queryStoreCommentList(params: IApiParam.StoreCommentList) {
  return request(`/v1/ume/client/comment/retail/storeCommentList?${stringify(params)}`)
}

/** 查詢零售門店評論詳情 */
export function queryStoreCommentInfo(params: IApiParam.CommentInfo) {
  return request(`/v1/ume/client/comment/retail/detail?${stringify(params)}`)
}

/** 查詢零售門店評論追評 */
export function queryStoreCommentReply(params: IApiParam.CommentInfo) {
  return request(`/v1/ume/client/comment/retail/child?${stringify(params)}`)
}

/** 查詢餐飲門店評論 */
export function queryCommentCateringList(params: IApiParam.StoreCommentList) {
  return request(`/v1/ume/client/comment/catering/list?${stringify(params)}`)
}

/** 查詢餐飲門店評論詳情 */
export function queryCommentCateringInfo(params: IApiParam.CommentInfo) {
  return request(`/v1/ume/client/comment/catering/detail?${stringify(params)}`)
}

/** 查詢餐飲門店評論追評 */
export function queryCommentCateringReply(params: IApiParam.CommentInfo) {
  return request(`/v1/ume/client/comment/catering/child?${stringify(params)}`)
}

/** 成為商戶會員 */
export function queryAddMerMember(data: IApiParam.AddMerMember) {
  return request(`/v1/ume/client/member`, {
    method: 'POST',
    data
  })
}

/** 查詢門店優惠券 */
export function queryStoreCoupon(data: IApiParam.StoreCoupon) {
  return request(`/v1/ume/client/coupon/list`, {
    method: 'POST',
    data
  })
}

/** 領取一張優惠券 */
export function queryClaimCoupon(data: IApiParam.ClaimCoupon) {
  return request(`/v1/ume/client/mycoupon/claim/coupon/one`, {
    method: 'POST',
    data
  })
}

/** 評論 讚 */
export function queryCommentLike(commentId: number, params: any) {
  return request(`/v1/ume/client/comment/like/${commentId}?${stringify(params)}`, { method: 'PUT' })
}

/** 評論 踩 */
export function queryCommentUnLike(commentId: number, params: any) {
  return request(`/v1/ume/client/comment/unlike/${commentId}?${stringify(params)}`, { method: 'PUT' })
}

/** 評論 回復 */
export function queryCommentReply(data: IApiParam.CommentReply) {
  return request(`/v1/ume/client/comment/reply`, { method: 'POST', data })
}

/** 評論 舉報 */
export function queryCommentComplaint(data: IApiParam.CommentComplaint) {
  return request(`/v1/ume/client/comment/saveComplain`, { method: 'POST', data })
}

/** 查詢禮包 */
export function queryStorePack(params: IApiParam.StorePack) {
  return request(`/v1/ume/client/store/pack?${stringify(params)}`)
}

/** 查詢已領取禮包 */
export function queryStorePackReceive(params: IApiParam.StorePack) {
  return request(`/v1/ume/client/pack/my/receive?${stringify(params)}`)
}