import { Message } from '@/design'
import { queryUserInfo } from '@/services/mine';
import { queryClaimCoupon } from '@/services/store';
import { queryUserIsRegister, queryUserSendCaptcha, queryUserLogin, queryUserRegister, queryUserMemberNo } from '@/services/user'
import { Ga_User } from '@/utils/business';
import { encryptMd5, isEmpty, JsonStorge, shakeIt, waitBy } from '@/utils/common';
import { Rgx_Phone_852, Rgx_Phone_86, Rgx_Phone_886 } from '@/utils/regular';
import { umeHistory } from '@/utils/route';

export default {
  namespace: 'user',
  state: {
    signData: {
      phoneAreaCode: '886',
      mobileNumber: '',
      action: ''
    },
    loginCaptcha: 0,
    userDetail: {}
  },
  effects: {
    /** 驗證是否註冊 */
    * fetchIsRegister(_: any, { call, put, select }: IDvaOperation) {
      const { phoneAreaCode, mobileNumber } = yield select((state: IDvaModel) => state.user.signData);
      let errMsg;
      switch (phoneAreaCode) {
        case '86':
          errMsg = !Rgx_Phone_86.test(mobileNumber) && '請輸入有效的大陸手機號'; break;
        case '852':
          errMsg = !Rgx_Phone_852.test(mobileNumber) && '請輸入有效的香港手機號'; break;
        case '886':
          errMsg = !Rgx_Phone_886.test(mobileNumber) && '請輸入有效的台灣手機號'; break;
        default: errMsg = '未知錯誤'; break;
      }
      if (errMsg) {
        Message.warn({ content: errMsg });
        return false;
      }
      // 驗證是否註冊
      let registerRes = yield call(queryUserIsRegister, { phoneAreaCode, mobileNumber });
      if (!registerRes) return false;
      yield put({
        type: 'saveSignData',
        payload: { action: registerRes.data === 'Y' ? 'login' : 'register' }
      })
      yield put({ type: 'fetchSendCaptcha' })
    },
    /** 發送驗證碼 */
    * fetchSendCaptcha({ payload }: any, { call, put, select }: IDvaOperation) {
      const { phoneAreaCode, mobileNumber, action } = yield select((state: IDvaModel) => state.user.signData);
      // 账号已注册，發送登錄驗證碼
      // 账号未注册，發送註冊驗證碼
      let captchaRes = yield call(queryUserSendCaptcha, {
        phoneAreaCode,
        mobileNumber,
        action
      })
      if (!captchaRes) return false;
      localStorage.setItem('login_captcha', '60');
      yield put({ type: 'saveLoginCaptcha', payload: 60 })
      if (payload) {
        yield put({ type: 'freshCaptchaTime' })
      }
      umeHistory.replace('/captcha')
    },
    /** 刷新驗證時間 */
    * freshCaptchaTime(_: any, { put }: IDvaOperation) {
      let loginCaptcha: number = Number(localStorage.getItem('login_captcha'));
      // console.log("🚀 ~ file: user.ts ~ line 47 ~ *freshCaptchaTime ~ loginCaptcha", loginCaptcha)
      if (loginCaptcha > 0) {
        loginCaptcha--;
        localStorage.setItem('login_captcha', loginCaptcha.toString());
        yield waitBy(1);
        yield put({ type: 'freshCaptchaTime' })
      } else {
        localStorage.removeItem('login_captcha')
      }
      yield put({ type: 'saveLoginCaptcha', payload: loginCaptcha })
    },
    /** 註冊/登入 */
    * fetchUserSign({ payload }: any, { call, put, select }: IDvaOperation) {
      const { phoneAreaCode, mobileNumber, action } = yield select((state: IDvaModel) => state.user.signData);
      let params: IApiParam.UserSign = {
        phoneAreaCode,
        mobileNumber,
        smsCode: payload
      }
      let result;
      if (action === 'register') {
        params['passwd'] = encryptMd5(mobileNumber.substr(-6));
        params['braId'] = localStorage.getItem('braId') || ''
        params['regMerMember'] = 1
        result = yield call(queryUserRegister, params)
      } else {
        result = yield call(queryUserLogin, params)
      }
      if (!result) return false;
      JsonStorge.set('userInfo', result.data)
      if (result?.data) {
        Ga_User(result.data?.userId || '');
      }
      localStorage.removeItem('login_captcha')
      if (action === 'register') {
        sessionStorage.setItem('new_mer_member', 'Y')
        sessionStorage.setItem('get_gift', 'Y')
      }
      Message.success({ content: action === 'register' ? '註冊成功' : '登入成功' })
      shakeIt(400);
      yield put({ type: 'checkUserLoginCallback' })
    },
    * fetchUserDetail(_: any, { call, put }: IDvaOperation) {
      let infos = JsonStorge.get('userInfo');
      let loading = Message.loading({ content: '正在查詢用戶信息…' })
      let result = yield call(queryUserInfo, { userId: infos.userId })
      let memberRes = yield call(queryUserMemberNo, infos.userId)
      loading.hide();
      if (!result) return false;
      yield put({
        type: 'saveUserDetail',
        payload: {
          ...result.data,
          userId: infos.userId,
          memberCode: (memberRes?.data && memberRes.data?.memberCode) || infos.userId
        }
      })
    },
    * checkUserLoginCallback(_: any, { call }: IDvaOperation) {
      // 判斷是否有優惠券待領取
      let { userId } = JsonStorge.get('userInfo')
      let { couponId, braId } = JsonStorge.get('gift_coupon')
      if (!isEmpty(couponId)) {
        let result = yield call(queryClaimCoupon, { couponId, userId });
        JsonStorge.remove('gift_coupon')
        if (result) {
          Message.success('優惠券已放入您的券包中~');
          umeHistory.switchTo(`/store/${braId}`)
        }
        umeHistory.switchTo(`/store/${braId}`)
      } else {
        umeHistory.goBack()
      }
    }
  },
  reducers: {
    saveSignData(state: IModelUser, { payload }: IDvaAction) {
      return {
        ...state,
        signData: {
          ...state.signData,
          ...payload
        }
      }
    },
    saveLoginCaptcha(state: IModelUser, { payload }: IDvaAction) {
      return {
        ...state,
        loginCaptcha: payload
      }
    },
    saveUserDetail(state: IModelUser, { payload }: IDvaAction) {
      return {
        ...state,
        userDetail: payload
      }
    },
  }
}